import React from "react";
import { MdDesignServices } from "react-icons/md";
import { TbWorldCode } from "react-icons/tb";
import { GiSmartphone, GiPencilBrush } from "react-icons/gi";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { LiaConnectdevelop } from "react-icons/lia";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
        <span className="green">Nuestros </span>Servicios
        </h4>
        <h1>¿Que hacemos?</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={TbWorldCode}
            title={"Desarrollo Web"}
            disc={`Creamos más que simples sitios web, construimos experiencias digitales. 
            Nuestra pasión radica en transformar visiones e ideas en plataformas web funcionales y estéticamente agradables.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={GiSmartphone}
            title={"App Móviles"}
            disc={`No solo desarrollamos aplicaciones; forjamos conexiones. 
            Cada aplicación móvil que diseñamos es una ventana al mundo, proporcionando soluciones a dedo y acercando a las personas a las experiencias que más desean.`}
          />
        </Slide>
      </Cards>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={LiaConnectdevelop}
            title={"Desarrollos a la Medida"}
            disc={`Prototipados, front end, back end, testeo de calidad. Un apoyo para ti, y tu equipo, utilizando la mejor y más actual tecnología.`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={GiPencilBrush}
            title={"Diseño Gráfico"}
            disc={`Transformamos ideas en imágenes que comunican, inspiran y capturan la esencia de tu marca
            dando vida a diseños que no solo son visualmente impresionantes, sino también efectivos en transmitir tu mensaje.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={PiPlugsConnectedBold}
            title={"Integración de Sistemas"}
            disc={`Desarrollo de sistemas sobre otros software, somos capaces de conectar nuestros programas con tus viejos programas o servicios.`}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;