import React from 'react'
import styled from 'styled-components';
import developer from './../../assets/img/developer.jpeg'

const CardInformation = (props) => {
    const { Icon, disc, title, secondText, thirdText, fourthText } = props;
  return (
    <Container>
        <div className='about_us' style={{ width : "50%"}}>
            <span className='green'></span>
            <h1>{title}</h1>
            <p>{disc}</p>
            <br/>
            <p>{secondText}</p>
            <br/>
            <p>{thirdText}</p>
            <br/>
            <p>{fourthText}</p>
        </div>
        <div style={{ width : "50%"}}>
            <img className='developer_img' src={developer}  style={{ width: "60%"}}/>
        </div>
    </Container>
  )
}

export default CardInformation;

const Container = styled.div`
    width: 100%;
    display: flex;
    background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
    padding: 1rem;
    text-align: center;
    height: 400px;
    @media(max-width : 500px){
        display: block;
        height: 580px;
    }
    span{
        font-size: 4rem;
    }
    
    h1{
        font-size: 1.2rem;
        padding-bottom: 1rem;
    }

    p{
        font-size: 0.8rem;
    }

    .developer_img{
        @media(max-width : 640px){
            display:none;
        }
    }

    .about_us{
        @media(max-width : 640px){
            width: 100% !important
        }
    }

`