import React from "react";
import { GiSmartphone, GiPencilBrush } from "react-icons/gi";
import styled from "styled-components";
import CardInformation from "./CardInformation";
import { Slide } from "react-awesome-reveal";

const OurInformation = () => {
  return (
    <Container id="aboutus">
      <Slide direction="down">
        <h1>
        <span className="green">Quiénes </span>Somos
        </h1>
      </Slide>
      <Cards>
        <Slide direction="right">
          <CardInformation
            disc={`Nuestro viaje comenzó con un grupo de apasionados programadores y visionarios tecnológicos, 
            quienes vieron una oportunidad en el mercado para ofrecer soluciones digitales que no sólo funcionaran de manera óptima, 
            sino que también fueran intuitivas, eficientes y, sobre todo, confiables.`}
            secondText={`A lo largo de estos años, hemos colaborado con una diversidad de clientes, 
            desde startups en crecimiento hasta corporaciones consolidadas, entregándoles herramientas personalizadas que impulsan su negocio y simplifican sus operaciones. 
            Cada proyecto que emprendemos es una oportunidad para demostrar nuestra dedicación, experiencia y el compromiso inquebrantable con la excelencia.`}
            thirdText={`Pero más allá de la programación y el código, lo que realmente nos define es nuestra pasión por la innovación y nuestra capacidad para escuchar, 
            entender y responder a las necesidades únicas de cada cliente. Creemos que la tecnología, cuando se aplica correctamente, tiene el poder de transformar empresas, 
            industrias e incluso la vida cotidiana.`}
            fourthText={`Gracias por considerarnos como tu socio tecnológico. En nuestra empresa, tu visión se convierte en realidad.`}
          />
        </Slide>
        
      </Cards>
    </Container>
  );
};

export default OurInformation;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;